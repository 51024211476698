import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Юридичні послуги Flateour
			</title>
			<meta name={"description"} content={"Ласкаво просимо до Flateour Legal Services, де ми спеціалізуємося на перетворенні ваших водійських невдач на перемоги. "} />
			<meta property={"og:title"} content={"Головна | Юридичні послуги Flateour"} />
			<meta property={"og:description"} content={"Ласкаво просимо до Flateour Legal Services, де ми спеціалізуємося на перетворенні ваших водійських невдач на перемоги. "} />
			<meta property={"og:image"} content={"https://flateour.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://flateour.live/img/106374_1_2.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://flateour.live/img/106374_1_2.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://flateour.live/img/106374_1_2.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://flateour.live/img/106374_1_2.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://flateour.live/img/106374_1_2.png"} />
			<meta name={"msapplication-TileImage"} content={"https://flateour.live/img/106374_1_2.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://flateour.live/img/1.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					background="#ffffff"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 32px/1.2 --fontFamily-sans" text-align="center">
						Юридичні послуги Flateour
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						Завдяки глибокому розумінню правил дорожнього руху та прагненню захистити ваші права, ми пропонуємо кваліфіковану юридичну допомогу щодо штрафів за перевищення швидкості, порушень правил паркування та інших юридичних проблем, пов'язаних з дорожнім рухом.
					</Text>
					<Button
						href="/contact"
						type="link"
						text-decoration-line="initial"
						background="--color-darkL1"
						padding="12px 30px 12px 30px"
						border-radius="25px"
					>
						Зв'яжіться з нами
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-29">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
				md-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box width="20%" sm-width="100%" md-margin="0px 0px 36px 0px">
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" sm-margin="0px 0px 20px 0px">
					Чому варто обрати юридичні послуги Flateour?
					<br />
				</Text>
			</Box>
			<Box width="80%" sm-width="100%" md-width="100%">
				<Box
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
				>
					<Text color="--dark" letter-spacing="-.7px" margin="0px 0px 8px 0px" font="--headline3">
						Експертиза в дорожньому праві
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Наша команда добре розбирається в тонкощах правил дорожнього руху, забезпечуючи кваліфіковане та ефективне представництво.
						<br />
					</Text>
				</Box>
				<Box
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Швидке та ефективне обслуговування
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Ми розуміємо важливість часу, особливо коли йдеться про порушення правил дорожнього руху. Наші налагоджені процеси спрямовані на швидке вирішення ваших проблем.
						<br />
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Клієнтоорієнтований підхід
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Ваш спокій - наш пріоритет. Ми слухаємо, розуміємо і розробляємо стратегію, щоб захистити ваші інтереси на дорозі.
						<br />
					</Text>
				</Box>
				<Box width="100%" border-color="--color-lightD2" margin="0px 0px 0px 0px">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
						Прозоре ціноутворення
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
						Ніяких прихованих витрат чи сюрпризів. Ми віримо в чітке, завчасне інформування щодо наших гонорарів.
						<br />
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://flateour.live/img/2.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Зв'яжіться з нами
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Ми інформуємо вас на кожному кроці, тому що розуміємо, наскільки стресовими можуть бути юридичні питання.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/"
							padding="0 0 0 0"
							background="0"
							color="--red"
						>
							КОНТАКТИ  &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});